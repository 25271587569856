<template>
 <div class="inner-section mt-3">
    <bread-cumb />
       <body-card>
       <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.master_purpose') }} {{ $route.params.id ? $t('globalTrans.modify') : $t('globalTrans.entry') }}</h4>
      </template>
          <b-container fluid>
              <b-row>
              <b-overlay :show="loading">
                <b-col sm="12" class="mt-3">
                  <slot>
                      <!-- =================Main application===================== -->
                    <div >
                      <ValidationObserver ref="prepare_schedule_form" v-slot="{ handleSubmit, reset }">
                        <!-- <b-overlay :show="loading"> -->
                          <b-form @submit.prevent="handleSubmit(cloneAddItem)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                            <b-row>
                              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                                  <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="year">
                                    <template v-slot:label>
                                      {{ $t('teaGardenConfig.service_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                          @change="changeSampleDetails"
                                          plain
                                          v-model="data.service_id"
                                          :options="serviceList"
                                          id="service_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                    <div class="invalid-feedback d-block">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form>
                        <!-- </b-overlay> -->
                      </ValidationObserver>
                    </div>
                    <!-- =================Add More Start===================== -->
                    <body-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('teaGardenBtriService.master_purpose') }}</h4>
                      </template>
                      <template v-slot:body>
                        <b-row>
                          <ValidationObserver ref="details_item" v-slot="{ handleDetailsItem, reset }">
                            <b-form @submit.prevent="handleDetailsItem(saveUpdate)" @reset.prevent="reset">
                            <b-row class="m-2">
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Purpose (En)" vid="purpose_en" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                <b-form-group
                                    label-for="purpose_en"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('teaGardenBtriService.purpose')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                  </template>
                                    <b-form-input
                                    plain
                                    v-model="details.purpose_en"
                                    id="purpose_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                </b-form-input>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                                </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Purpose (Bn)" vid="purpose_bn" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                  <b-form-group
                                      label-for="purpose_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.purpose')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                    </template>
                                      <b-form-input
                                      plain
                                      v-model="details.purpose_bn"
                                      id="purpose_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            </b-form>
                          </ValidationObserver>
                        </b-row>
                      </template>
                    </body-card>
                    <br/>
                    <div class="d-flex justify-content-end">
                      <b-button class="btn-sm mb-2" type="button" @click="addMoreDetailsInfo" variant="success">
                        <span v-if="loading">
                            <b-spinner small label="Loading..."></b-spinner>
                        </span>
                        <span v-else>
                            <i class="fas fa-plus-circle m-0"></i>
                        </span>
                        {{data.details && data.details.length > 0 ? $t('elearning_tpm.add_more_material') : $t('elearning_tpm.add_material')}}
                    </b-button>
                    </div>
                    <!-- schedule details -->
                      <div class="table-wrapper table-responsive">
                      <ValidationObserver ref="prepare_schedule_form_details">
                        <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr class="bg-primary">
                                        <th scope="col" style="width: 7%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                        <th scope="col" style="width: 20%" class="text-center"><div>{{ $t('teaGardenBtriService.purpose') }} {{ $t('globalTrans.en') }}</div></th>
                                        <th scope="col" style="width: 20%" class="text-center"><div>{{ $t('teaGardenBtriService.purpose') }} {{ $t('globalTrans.bn') }}</div></th>
                                        <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="data.details && data.details.length > 0">
                                        <tr v-for="(item, index) in data.details" :key="index">
                                            <td  class="text-center">{{ $n(index+1) }}</td>
                                            <td class="text-center">
                                              <ValidationProvider name="Purpose (En)" :vid="`purpose_en-${index}`" :rules="{ required: true }">
                                            <b-form-group
                                                label-for="purpose_en"
                                                slot-scope="{ valid, errors }"
                                            >
                                              <b-form-input
                                                plain
                                                v-model="item.purpose_en"
                                                id="purpose_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              </b-form-input>
                                              <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider></td>
                                            <td class="text-center"><ValidationProvider name="Purpose (En)" :vid="`purpose_bn-${index}`" :rules="{ required: true }">
                                              <b-form-group
                                                  label-for="purpose_en"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                <b-form-input
                                                  plain
                                                  v-model="item.purpose_bn"
                                                  id="purpose_en"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback d-block">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                            </ValidationProvider></td>
                                            <td class="text-center">
                                              <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="addMoreDetailsInfoRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                      <tr>
                                        <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                      </tr>
                                    </template>
                                </tbody>
                        </table>
                      </ValidationObserver>
                      </div>
                    <!-- =================Add More End===================== -->
                    <br>
                </slot>
                <b-row class="text-right mb-3">
                  <b-col>
                    <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                  </b-col>
                </b-row>
                </b-col>
                </b-overlay>
              </b-row>
          </b-container>
     </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { masterPurposeStore, masterPurposeUpdate } from '../../../api/routes'
import BreadCumb from '../../../../../../components/BreadCumb.vue'
export default {
  name: 'Form',
  props: ['id'],
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.$route.params.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      circularLoadings: false,
      data: {
        service_id: 0,
        status: 0,
        details: []
      },
      garden_infos: {},
      assign_users: {},
      userList: [],
      details: {
        purpose_en: '',
        purpose_bn: ''
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      const tmp = this.getEditEntry()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    serviceList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(2))
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.localizeUserList(newVal)
      }
    }
  },
  methods: {
    back () {
      this.$router.push('/tea-garden-service/btri/master-purpose')
    },
    changeSampleDetails () {
      this.data.details = []
    },
    addMoreAssignUserInfoRemove (index) {
      this.details.assign_users.splice(index, 1)
    },
    addMoreDetailsInfo () {
      if (this.details.purpose_en && this.details.purpose_bn) {
        const exist = this.data.details.find(detailItem => detailItem.purpose_en === this.details.purpose_en)
        const exist2 = this.data.details.find(detailItem => detailItem.purpose_bn === this.details.purpose_bn)
        if (!exist && !exist2) {
          this.data.details.push(this.details)
          this.details = {
            purpose_en: '',
            purpose_bn: ''
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: '#E73813 '
        })
        }
      } else {
        this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.invalidEntry'),
          color: '#E73813 '
        })
      }
    },

    addMoreDetailsInfoRemove (index) {
      this.data.details.splice(index, 1)
    },

    addMoreEdit (data, index) {
    },

    async saveUpdate (status = 1) {
      const isValid = await this.$refs.prepare_schedule_form.validate()
      const schedueDetailsValid = await this.$refs.prepare_schedule_form_details.validate()
      if (this.data.details.length === 0) {
          this.$toast.success({
            title: 'Warning',
            message: this.$t('elearning_tpm.data_empty'),
            color: '#E73813 '
          })
        }
        this.data.status = status
        if (isValid && schedueDetailsValid && this.data.details.length > 0) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        if (this.$route.params.id) {
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${masterPurposeUpdate}/${this.$route.params.id}`, this.data)
        } else {
          result = await RestApi.postData(teaGardenServiceBaseUrl, masterPurposeStore, this.data)
        }

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.$route.params.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push('/tea-garden-service/btri/master-purpose')
        } else {
          this.$refs.prepare_schedule_form.setErrors(result.errors)
        }
      }
    },
    localizeUserList (locale) {
      const tmp = this.userList.map(item => {
        return Object.assign({}, item, {
          text: locale === 'en' ? item.name : item.name_bn
        })
    })
      this.userList = tmp
    },
    getEditEntry () {
      const itemId = this.$route.params.id
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
